import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const IMAGE_PROPS = {
  width: 72,
  height: 72,
  className: 'rounded-full',
};

export default function Testimonials() {
  const [showMore, setShowMore] = useState(false);

  return (
    <section className="container sm:my-20 my-12">
      <div className="grid lg:grid-cols-12 gap-8">
        <div className="lg:col-span-10 lg:col-start-2">
          <div className="grid md:grid-cols-2 col-span-1 md:gap-8 reveal-wrapper">
            <div className="col-span-1 space-y-8">
              <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-blue mb-12">
                What our clients are saying
              </h2>

              <Testimonial
                className="bg-lighter-green"
                image={
                  <StaticImage
                    src="../images/testimonials/morgan-meldrum.jpg"
                    alt="Morgan Meldrum"
                    {...IMAGE_PROPS}
                  />
                }
                name="Morgan Meldrum"
                text="We have been working with Livecasts for years now and can highly recommend them. Our major events have hundreds of participants usually from 50+ countries, ith panels, keynote speakers, heads of state and prime ministers. They work to the highest standards (we don't accept anything less) and they are very competitive in their pricing."
                occupation="Chief Information Officer, World Jewish Congress"
              />

              <Testimonial
                className="bg-light-grey"
                image={
                  <StaticImage
                    src="../images/testimonials/olaf-bruns.jpg"
                    alt="Olaf Bruns"
                    {...IMAGE_PROPS}
                  />
                }
                name="Olaf Bruns"
                text="When we organised a debate on the global economic crisis at the European Parliament, with former ECB president Jean-Claude Trichet and today's European Commission president Jean-Claude Juncker, we went for Livecasts. In no time they made the livestream of the debate possible, which then was broadcast on their platform, on our Euronews website as well as on the website of our co-organisers, EU40. Livecasts was the most reliable and scalable solution for streaming that we at Euronews opted for. Strongly recommended."
                occupation="Deputy Bureau Chief Brussels, Euronews"
              />
            </div>

            <div
              className={`
                ${showMore ? 'block' : 'hidden'}
                md:block col-span-1 space-y-8 mt-8 md:mt-0
              `}
            >
              <Testimonial
                className="bg-light-grey"
                image={
                  <StaticImage
                    src="../images/testimonials/khristine.jpg"
                    alt="Khristine Custodio Gudczinski"
                    {...IMAGE_PROPS}
                  />
                }
                name="Khristine Custodio Gudczinski"
                text="Livecasts provides excellent service. They are well-organised and confident when dealing with high profile speakers. From the procurement process to live streaming our event, they handled each aspect professionally. I highly recommend them!"
                occupation="Event coordinator, UNDP World Oceans Day 2021 virtual celebration and Communications Consultant for the UNDP Ocean Innovation Challenge"
              />

              <Testimonial
                className="bg-lighter-green"
                image={
                  <StaticImage
                    src="../images/testimonials/lucian-goleanu.jpg"
                    alt="Lucian Goleanu"
                    {...IMAGE_PROPS}
                  />
                }
                name="Lucian Goleanu"
                text="Constantly improving and re-inventing itself, Livecasts offers high quality live streaming services and audio-visual solutions-adapted to customer's needs. A professional approach in a changing environment, keeping up to date with technology."
                occupation="Press Officer, Renew Europe Group, European Parliament"
              />

              <Testimonial
                className="bg-light-grey"
                image={
                  <StaticImage
                    src="../images/testimonials/kelly-vercauteren.jpg"
                    alt="Kelly Vercauteren"
                    {...IMAGE_PROPS}
                  />
                }
                name="Kelly Vercauteren"
                text="Thank you for your superb assistance, clear communication and excellent preparation skills in delivering our end product!"
                occupation="Management Assistant, Guberna - Instituut voor Bestuurders"
              />
            </div>

            <div
              className={`
                ${showMore ? 'hidden' : 'block'}
                md:hidden col-span-1 text-center mt-8
              `}
            >
              <button
                className="text-primary text-center font-bold text-base"
                onClick={() => setShowMore(!showMore)}
              >
                More from our clients
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function Testimonial({ image, text, name, occupation, className }) {
  return (
    <div
      className={`
        p-6 md:p-8 md:pt-6 md:pr-12
        rounded-md ${className}
      `}
    >
      {image}

      <p className="font-inter text-base text-black text-opacity-70 mt-4 mb-6">
        &ldquo;{text}&rdquo;
      </p>
      <h6 className="text-black text-opacity-70 font-bold mb-1">{name}</h6>
      <p className="text-black text-opacity-70">{occupation}</p>
    </div>
  );
}
