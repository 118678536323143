import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import arrow from '../images/event-types/arrow.svg';
import live from '../images/event-types/live.svg';

const IMAGE_PROPS = {
  width: 600,
  height: 358,
  className: 'rounded-md',
};

const BACKGROUND_PROPS = {
  width: 758,
  height: 714,
  objectFit: 'cover',
  className: 'rounded-md w-full h-full',
};

const EventTypes = () => {
  return (
    <div
      className="
        container relative
        grid grid-cols-1 sm:grid-cols-2 gap-8 items-start
      "
    >
      <Card
        className="mt-14"
        title="Virtual Events"
        description="Hold a live interactive online event, webinar or conference"
        link="/virtual-events"
        image={
          <StaticImage
            src="../images/event-types/virtual-event.jpg"
            alt="Woman in a video conference call"
            {...IMAGE_PROPS}
          />
        }
        backgroundImage={
          <StaticImage
            src="../images/event-types/virtual-event-background.jpg"
            alt="Living room"
            {...BACKGROUND_PROPS}
          />
        }
      />

      <Card
        title="On-site Events"
        description="Extend your reach beyond your physical event capacity"
        link="/on-site-events"
        image={
          <StaticImage
            src="../images/event-types/on-site-event.jpg"
            alt="Conference"
            {...IMAGE_PROPS}
          />
        }
        backgroundImage={
          <StaticImage
            src="../images/event-types/on-site-event-background.jpg"
            alt="Conference room"
            {...BACKGROUND_PROPS}
          />
        }
      />
    </div>
  );
};

function Card({ title, description, link, image, backgroundImage, className }) {
  return (
    <Link
      to={link}
      className={`
        block col-span-1 relative overflow-hidden rounded-md
        transition focus-ring group
        ${className}
      `}
    >
      <div className="absolute z-0 w-full h-full">{backgroundImage}</div>

      <div
        className="
          relative
          p-6 pt-8 md:p-8 md:pt-10 lg:p-14 lg:pt-16
        "
      >
        <div className="space-y-2">
          <h3 className="text-blue text-xl md:text-2xl lg:text-3xl font-bold">{title}</h3>

          <p className="lg:w-3/4 text-blue text-opacity-80 text-lg leading-relaxed">
            {description}
          </p>

          <div className="flex items-center">
            <span
              className="
                text-base text-primary font-bold
                transition group-hover:text-dark-primary
              "
            >
              Learn More
            </span>

            <img
              src={arrow}
              alt="Arrow"
              className="ml-3 transition transform group-hover:translate-x-2"
            />
          </div>
        </div>

        <div className="relative mt-4 md:mt-6 xl:mt-8">
          {image}
          <img src={live} alt="Live" className="absolute top-4 right-6" />
        </div>
      </div>
    </Link>
  );
}

export default EventTypes;
