import React from 'react';
import SEO from '../components/SEO';
import GreenTestimony from '../components/GreenTestimony';
import AllIncluded from '../components/AllIncluded';
import Header from '../components/Header';
import Hero from '../components/Hero';
import EventTypes from '../components/EventTypes';
import Partners from '../components/Partners';
import Premium from '../components/Premium';
import ReadyToStart from '../components/ReadyToStart';
import Testimonials from '../components/Testimonials';
import FAQ from '../components/FAQ';
import Footer from '../components/Footer';

const HomePage = () => {
  return (
    <div>
      <SEO titleTemplate="%s" />

      <Header />
      <Hero
        title="Webcasting services you can rely on"
        subtitle="Our team will guide you every step of the way and deliver the best online experience for your audience with Livecasts"
      />
      <EventTypes />
      <Partners />
      <GreenTestimony />
      <AllIncluded />
      <Premium />
      <Testimonials />
      <ReadyToStart />
      <FAQ />
      <Footer />
    </div>
  );
};

export default HomePage;
