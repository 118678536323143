import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function GreenTestimony() {
  return (
    <section className="container mt-16">
      <div className="bg-green rounded-md">
        <div
          className="
            grid grid-flow-col-dense md:grid-cols-3 gap-4 md:gap-8 items-start
            p-6 md:p-8 lg:p-0
          "
        >
          <div className="row-start-2 md:row-start-1 md:col-start-2 md:col-span-2 lg:col-start-1 self-center lg:p-12">
            <blockquote
              className="
                text-base md:text-xl xl:text-2xl
                md:leading-relaxed xl:leading-relaxed
                font-bold text-white
                mb-6 lg:mb-8 xl:mb-12
              "
            >
              &rdquo;The Livecasts team helped us make our online event happen successfully in a
              short time frame. They were very responsive and accommodating and helped us find the
              best technical solutions for our specific needs including solutions for simultaneous
              translation.&rdquo;
            </blockquote>

            <p className="text-base md:text-lg font-bold text-white mb-1 xl:mb-2">Pia Cencig</p>
            <p className="text-base text-white text-opacity-70">
              Membership &amp; Communication Officer, ECOS
            </p>
          </div>

          <div className="lg:h-full">
            <StaticImage
              src="../images/pia.jpg"
              alt="Pia Cencig"
              objectFit="cover"
              objectPosition="center top"
              className="
                w-16 h-16 md:w-full md:h-auto lg:w-auto lg:h-full
                rounded-full md:rounded-md lg:rounded-l-none
              "
            />
          </div>
        </div>
      </div>
    </section>
  );
}
